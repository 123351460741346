import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import dotenv from 'dotenv';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import * as Sentry from '@sentry/react';

import '@/core/index.css';
import { config } from '@/core/wagmiConfig';
import { Landing } from '@/core/Landing';
import { ModalProvider } from '@/services/modal';
import { ThemeProvider } from '@/services/theme';
import { TranslationProvider } from '@/services/locale';
import { AppConfigProvider } from '@/core/AppConfig';
import { ScrollToTop } from '@/shared/components/ScrollToTop';
import { ErrorBoundary } from '@/shared/components/ErrorBoundary';
import { isProduction } from '@/core/config';

dotenv.config();

const queryClient = new QueryClient();

Sentry.init({
  dsn: 'https://5b0bc1c65eb281d9371aa7cdfe520b96@o4508737229553664.ingest.de.sentry.io/4508755473072208',
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  enabled: isProduction,
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <AppConfigProvider>
        <WagmiProvider config={config}>
          <QueryClientProvider client={queryClient}>
            <RainbowKitProvider>
              <ThemeProvider>
                <ModalProvider>
                  <TranslationProvider>
                    <Router>
                      <ScrollToTop />
                      <Landing />
                    </Router>
                  </TranslationProvider>
                </ModalProvider>
              </ThemeProvider>
            </RainbowKitProvider>
          </QueryClientProvider>
        </WagmiProvider>
      </AppConfigProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);
