import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';

export interface IErrorBoundaryProps {
  fallback?: React.ReactElement;
  children: React.ReactNode;
}

function DefaultFallback() {
  return <div>Something went wrong</div>;
}

export function ErrorBoundary({ children, fallback, ...props }: IErrorBoundaryProps) {
  const renderedFallback = fallback || DefaultFallback;

  return (
    <SentryErrorBoundary fallback={renderedFallback} {...props}>
      {children}
    </SentryErrorBoundary>
  );
}
